import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getPincode = createAsyncThunk("getPincode", async (url) => {
  try {
    const response = await axios.get(url);
    return response ? response : null;
  } catch (error) {
    console.log(error);
    return null;
  }
});

const pincodeSlice = createSlice({
  name: "pincode",
  initialState: {
    pincode: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPincode.pending, (state) => {
        state.loader = true;
      })
      .addCase(getPincode.fulfilled, (state, action) => {
        state.pincode = action.payload?.data;
        state.loader = false;
      })
      .addCase(getPincode.rejected, (state) => {
        state.pincode = null;
        state.loader = false;
      });
  },
});

export default pincodeSlice.reducer;
