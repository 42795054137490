import { createSlice } from "@reduxjs/toolkit";

const confirmModalSlice = createSlice({
  name: "confirmModal",
  initialState: {
    confirmModal: {
      isOpen: false,
      id: null,
      callback: null,
      body: "Are You Sure Want To Logout",
      title: "Logout",
    },
  },
  reducers: {
    handleConfirmModal: (state, action) => {
      state.confirmModal = action?.payload;
    },
  },
});

export const { handleConfirmModal } = confirmModalSlice.actions;

export default confirmModalSlice.reducer;
