import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getBlogData = createAsyncThunk("getBlogData", async (url) => {
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getSingleBlogData = createAsyncThunk(
  "getSingleBlogData",
  async ({ url, data }) => {
    try {
      const response = await axios.get(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const blogSlice = createSlice({
  name: "blogs",
  initialState: {
    Loading: false,
    blogsData: null,
    singleBlogData: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getBlogData.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getBlogData.fulfilled, (state, action) => {
        state.blogsData = action?.payload?.data;
        state.Loading = false;
      })
      .addCase(getBlogData.rejected, (state) => {
        state.Loading = false;
      })
      .addCase(getSingleBlogData.fulfilled, (state, action) => {
        state.singleBlogData = action?.payload?.data;
        state.Loading = false;
      });
  },
});

export default blogSlice.reducer;
