import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";

export const getCompany = createAsyncThunk("getCompany", async (url) => {
  try {
    const res = await http.get(url);
    return res?.data;
  } catch (error) {
    return { data: null };
  }
});

const companySlice = createSlice({
  name: "company",
  initialState: {
    loading: false,
    company: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.company = action?.payload?.data;
      })
      .addCase(getCompany.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default companySlice.reducer;
