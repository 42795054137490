import React, { useState, useEffect } from "react";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import { requestPermission, onMessageListener } from "../../firebase";

function Notification() {
  const [notification, setNotification] = useState({ title: "", body: "" });
  useEffect(() => {
    requestPermission();
    const unsubscribe = onMessageListener().then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      Store.addNotification({
        title: `${payload?.notification?.title}` || notification?.title,
        message: `${payload?.notification?.body}` || notification?.body,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 10000,
          onScreen: true,
        },
      });
    });
    return () => {
      unsubscribe.catch((err) => {
        console.log("failed", err);
      });
    };
  }, []);

  return (
    <>
      <div>
        <ReactNotifications />
      </div>
    </>
  );
}

export default Notification;
