import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isOpen: false,
    isOtpOpen: false,
    isDummyOpen: false,
    isPayment: false,
  },
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    openOtpModal: (state) => {
      state.isOtpOpen = true;
    },
    closeOtpModal: (state) => {
      state.isOtpOpen = false;
    },
    openDummyOtpModal: (state) => {
      state.isDummyOpen = true;
    },
    closeDummyOtpModal: (state) => {
      state.isDummyOpen = false;
    },
    openPaymentModal: (state) => {
      state.isPayment = true;
    },
    closePaymentModal: (state) => {
      state.isPayment = false;
    },
  },
});

export const {
  openModal,
  closeModal,
  openOtpModal,
  closeOtpModal,
  openDummyOtpModal,
  closeDummyOtpModal,
  openPaymentModal,
  closePaymentModal,
} = loginSlice.actions;

export default loginSlice.reducer;
