import React from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { handleConfirmModal } from "../../Redux/confirmModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleRemoveCart } from "../../Redux/features/cartSlice";

const CustomConfirmModal = () => {
  const dispatch = useDispatch();
  const { confirmModal } = useSelector((state) => state?.isConfirmModal);
  const handleClick1 = () => {
    dispatch(
      handleConfirmModal({
        ...confirmModal,
        isOpen: false,
        id: null,
        callback: null,
        body: null,
        title: null,
      })
    );
    confirmModal.callback(false, confirmModal.id);
  };
  const handleClick2 = () => {
    dispatch(
      handleConfirmModal({
        ...confirmModal,
        isOpen: false,
        id: null,
        callback: null,
        body: null,
        title: null,
      })
    );
    confirmModal.callback(true, confirmModal.id);
    dispatch(handleRemoveCart(null));
  };
  return (
    <Modal
      open={confirmModal?.isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          py: 4,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80vw", md: "35vw" },
          height: { xs: "auto", md: "auto" },
          px: 2,
          bgcolor: "background.paper",
          background: "#ffffff",
          border: "0.5px solid rgba(35, 35, 35, 0.5)",
          boxShadow: "4px 4px 7px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Poppins",
          fontSize: { md: "1.2rem", xs: "0.8rem" },
        }}
      >
        <IconButton
          onClick={() =>
            dispatch(handleConfirmModal({ ...confirmModal, isOpen: false }))
          }
          sx={{
            position: "absolute",
            top: "3%",
            right: "1%",
            cursor: "pointer",
            borderRadius: 2,
            p: 0.2,
            border: 1,
            borderColor: "secondary.main",
          }}
        >
          <CloseIcon
            sx={{
              borderColor: "secondary.main",
            }}
            color="secondary"
            fontSize="medium"
          />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mt: -2,
            mb: 3,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { md: "1.5rem", xs: "1.3rem" },
              fontWeight: 500,
              ml: "1rem",
            }}
          >
            {confirmModal?.title || ""}
          </Typography>
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { md: "1.2rem", xs: "1rem" },
            fontWeight: 500,
          }}
        >
          {confirmModal?.body || ""}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            mt: 2.5,
          }}
        >
          <Button
            size="large"
            variant="outlined"
            sx={{
              width: { xs: "40%", md: "45%" },
              height: { xs: "2rem", md: "2.7rem" },
              color: "secondary.main",
              "&:hover": {
                bgcolor: "white.main",
                border: "1px solid black",
              },
              textTransform: "capitalize",
            }}
            onClick={handleClick1}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            sx={{
              width: { xs: "40%", md: "45%" },
              height: { xs: "2rem", md: "2.7rem" },
              color: "white.main",
              bgcolor: "secondary.main",
              textTransform: "capitalize",
            }}
            onClick={() => {
              handleClick2();
              localStorage.clear();
            }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomConfirmModal;
