import React, { useMemo } from "react";
import { routes } from "../../Routes";
import { Route, Routes } from "react-router-dom";

const ProtectedRoutes = () => {
  const userId = localStorage.getItem("_id");
  const processedRoutes = useMemo(() => {
    if (routes && routes?.length) {
      return routes?.map((ele, i) =>
        ele?.protected && userId ? (
          <Route
            key={i}
            path={ele?.path}
            element={ele?.component}
            title={ele?.title}
          />
        ) : !ele?.protected ? (
          <Route
            key={i}
            path={ele?.path}
            element={ele?.component}
            title={ele?.title}
          />
        ) : null
      );
    }
  }, [routes, userId]);

  return <Routes>{processedRoutes}</Routes>;
};

export default ProtectedRoutes;
