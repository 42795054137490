import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getCouponData = createAsyncThunk(
  "getCouponData",
  async ({ url, data }) => {
    try {
      const response = await axios.get(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    loading: false,
    getCoupon: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCouponData.fulfilled, (state, action) => {
      state.loading = false;
      state.getCoupon = action?.payload?.data;
    });
  },
});

export default couponSlice.reducer;
