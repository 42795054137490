import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer, IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { handleOpenDrawerLeft } from "../../Redux/features/leftDrawerSlice";
import {
  AboutUsIcon,
  BlogIcons,
  ContactUsIcons,
  PrivacyPolicyIcon,
  ReturnRequestIcon,
  SupportPolicyIcon,
  TermsAndConditionIcon,
} from "../../Assets/svg";
import { useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  getAllCategorySequence,
  handleSubCategory,
} from "../../Redux/features/navbarSlice";

const DrawerLeftArr = [
  {
    id: 1,
    icone: <ContactUsIcons />,
    title: "Contact Us",
    link: "/contactUs",
  },
  {
    id: 2,
    icone: <BlogIcons />,
    title: "Blog",
    link: "/blog",
  },
  {
    id: 3,
    icone: <PrivacyPolicyIcon />,
    title: "Privacy Policy",
    link: "/privacyPolicy",
  },
  {
    id: 4,
    icone: <TermsAndConditionIcon />,
    title: "Term & Condition",
    link: "/term&conditione",
  },
  {
    id: 5,
    icone: <ReturnRequestIcon />,
    title: "Return Policy",
    link: "/returnPolicy",
  },
  {
    id: 7,
    icone: <SupportPolicyIcon />,
    title: " Support Policy",
    link: "/supportPolicy",
  },
  {
    id: 6,
    icone: <AboutUsIcon />,
    title: "About Us",
    link: "/aboutUs",
  },
  // {
  //   id: 8,
  //   icone: <StartIcons />,
  //   title: "Rate Us",
  //   link: "/Terms&Conditions/6412a488aba2ff6383f3fa82",
  // },
];

export default function DrawerComponents() {
  const { state } = useSelector((rr) => rr.isLeftDrawerByShubham);
  const { company } = useSelector((state) => state?.isCompany);
  const { subTitle, parentTitle } = useSelector((state) => state?.isNavbar);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getAllCategorySequence({
        url: `${process.env.REACT_APP_API}/getCategoryBySequence`,
      })
    ).then((res) => {
      if (res?.payload) {
        const subCate = [];
        const newA = res?.payload?.data?.at(0)?.children;
        subCate?.push(...newA);
        dispatch(handleSubCategory(subCate));
      }
    });
  }, []);

  return (
    <div>
      <Drawer
        state={true}
        anchor="left"
        open={state}
        PaperProps={{
          sx: {
            backgroundColor: "white.main",
            width: "80vw",
            minHeight: "10000",
            p: 1,
            color: "secondary.main",
            textAlign: "center",
            overflow: "hidden",
          },
        }}
        onClose={() => {
          dispatch(handleOpenDrawerLeft(false));
        }}
      >
        <Box sx={{ overflow: "auto" }}>
          <Box
            sx={{
              display: "flex",
              aline: "flex-end",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton
              aria-label=""
              sx={{
                right: "2%",
              }}
              onClick={() => dispatch(handleOpenDrawerLeft(false))}
            >
              <Cancel sx={{ fontSize: 30 }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "70%",
              height: "4.5rem",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
            onClick={() => {
              navigate("/");
              document.documentElement.scrollTop = 0;
              document.scrollingElement.scrollTop = 0;
            }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                // objectFit: "cover",
                cursor: "pointer",
              }}
              src={`${process.env.REACT_APP_URI}/${company?.header_logo}`}
              alt="Pic"
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../Assets/Images/Footer/FooterPic.jpg");
              }}
            />
          </Box>
          <Sidebar
            style={{
              width: "100%",
              color: "black",
              fontSize: "1rem",
              fontWeight: 500,
            }}
          >
            <Menu
              menuItemStyles={{
                button: {
                  // the active class will be added automatically by react router
                  // so we can use it to style the active menu item
                  [`&.active`]: {
                    backgroundColor: "#13395e",
                    color: "#b6c8d9",
                  },
                },
              }}
            >
              <SubMenu style={{ textAlign: "start" }} label="Shop By Category">
                {parentTitle && parentTitle?.length > 0 ? (
                  parentTitle?.map((item, j) => (
                    <SubMenu
                      key={j}
                      style={{ textAlign: "start" }}
                      label={`${item?.name}`}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                          getAllCategorySequence({
                            url: `${process.env.REACT_APP_API}/getCategoryBySequence`,
                          })
                        ).then((res) => {
                          if (res?.payload) {
                            const subCate = [];
                            const newA = res?.payload?.data?.at(j)?.children;
                            subCate?.push(...newA);
                            dispatch(handleSubCategory(subCate));
                          }
                        });
                      }}
                    >
                      {subTitle && subTitle?.length > 0 ? (
                        subTitle?.map((sub, a) => (
                          <SubMenu
                            key={a}
                            style={{ textAlign: "start" }}
                            label={`${sub?.name}`}
                          >
                            {sub?.children?.length > 0 ? (
                              sub?.children?.map((child, b) => (
                                <MenuItem
                                  key={b}
                                  style={{ textAlign: "start" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/filter/${child?._id}`, {
                                      state: { category: child?._id },
                                    });
                                    dispatch(handleOpenDrawerLeft(false));
                                  }}
                                >
                                  {child?.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem style={{ textAlign: "start" }}>
                                No data
                              </MenuItem>
                            )}
                          </SubMenu>
                        ))
                      ) : (
                        <MenuItem style={{ textAlign: "start" }}>
                          No data
                        </MenuItem>
                      )}
                    </SubMenu>
                  ))
                ) : (
                  <MenuItem style={{ textAlign: "start" }}>No data</MenuItem>
                )}
              </SubMenu>

              {DrawerLeftArr?.map((ele, i) => (
                <MenuItem
                  key={i}
                  style={{ textAlign: "start" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`${ele?.link}`);
                    dispatch(handleOpenDrawerLeft(false));
                  }}
                >
                  {ele?.title}{" "}
                </MenuItem>
              ))}
            </Menu>
          </Sidebar>
        </Box>
      </Drawer>
    </div>
  );
}
