import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const OfferNavbar = () => {
  const { getHome } = useSelector((state) => state?.isHome);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "1.5rem",
          backgroundColor: "primary.main",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: ".8rem", color: "white.main" }}
        >
          {getHome?.offerOnOccasion?.title || "No Offers Present There"}
        </Typography>
      </Box>
    </>
  );
};

export default OfferNavbar;
