import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { closeOtpModal } from "../../Redux/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { sendOtp } from "../../Redux/features/userSlice";
import { handleAlert } from "../../Redux/alertSlice";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";

const OtpModal = () => {
  const { isOtpOpen } = useSelector((state) => state?.isLogin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loader, userData } = useSelector((state) => state?.isUser);
  const [OTP, setOTP] = useState("");

  const handleOtp = (e) => {
    e.preventDefault();
    const otp = localStorage.getItem("otp");
    if (otp === OTP) {
      dispatch(closeOtpModal());
      dispatch(
        handleAlert({
          isOpen: true,
          type: "success",
          msg: "OTP Verified",
        })
      );
      localStorage.removeItem("otp");
      localStorage.setItem("_id", userData?._id);
      localStorage.setItem("token", userData?.token);
      if (localStorage.getItem("isMember")) {
        navigate("/profileDashboard", { state: { member: 4 } });
        // localStorage.removeItem("isMember")
      } else {
        navigate("/");
      }
      setOTP("");
    } else {
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "Invalid OTP",
        })
      );
    }
  };

  const handleResendOTP = () => {
    dispatch(
      sendOtp({
        url: `${process.env.REACT_APP_API}/sendOtp`,
        data: { phoneNumber: userData?.phoneNumber.toString(), check: true },
      })
    ).then((res) => {
      if (res?.payload) {
        setOTP("");
        dispatch(
          handleAlert({
            isOpen: true,
            type: "success",
            msg: "Resend OTP",
          })
        );
      }
    });
  };
  const renderTime = (remainingTime) => {
    return (
      <Box sx={{ fontSize: "0.8rem" }}>
        Resend the OTP in{" "}
        {remainingTime !== 0 && (
          <span style={{ color: "red", marginLeft: "5.5rem" }}>
            00:{remainingTime} sec
          </span>
        )}
      </Box>
    );
  };
  const renderButton = (buttonProps) => {
    return (
      <Box sx={{ fontSize: "0.8rem" }} {...buttonProps}>
        {buttonProps.remainingTime !== 0 ? null : (
          <Button
            sx={{
              height: "1rem",
              textTransform: "capitalize",
              color: "secondary.main",
            }}
            variant="text"
            {...buttonProps}
          >
            Resend
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOtpOpen}
        // onClose={() => dispatch(closeOtpModal())}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOtpOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { md: "30rem", xs: "80vw" },
              height: { md: "auto", xs: "auto" },
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 3,
            }}
          >
            <Box
              gap={3}
              sx={{
                py: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  border: 2,
                  borderRadius: 1,
                  p: 0.2,
                  color: "secondary.main",
                }}
                onClick={() => dispatch(closeOtpModal())}
              >
                <CloseIcon
                  sx={{
                    p: 0.2,
                    color: "secondary.main",
                  }}
                />
              </IconButton>
              <Typography sx={{ fontSize: "1.8rem", fontWeight: 600, mb: 2 }}>
                OTP Verify
              </Typography>
              <form onSubmit={handleOtp}>
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={loader}
                  secure
                  inputStyles={{
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "0.5rem",
                    backgroundColor: "#D9D9D9",
                    border: "none",
                  }}
                />
                <ResendOTP
                  style={{
                    padding: "0 1px 0 1px",
                    margin: "1rem 0rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  maxTime="59"
                  onResendClick={handleResendOTP}
                  renderTime={renderTime}
                  renderButton={renderButton}
                />
                <LoadingButton
                  sx={{
                    height: "2.8rem",
                    backgroundColor: "secondary.main",
                    width: "100%",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    color: "white.main",
                    "&:hover": {
                      color: "white.main",
                      backgroundColor: "secondary.main",
                    },
                  }}
                  loading={loader}
                  loadingPosition="center"
                  type="submit"
                  variant="contained"
                >
                  Verify OTP
                </LoadingButton>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default OtpModal;
