import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllWalletTransaction = createAsyncThunk(
  "getAllWalletTransaction",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error, "Something Went Wrong !");
      return error?.response?.data;
    }
  }
);

export const walletRequest = createAsyncThunk(
  "walletRequest",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error, "Something Went Wrong !");
      return error?.response?.data;
    }
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    loader: false,
    walletTransaction: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllWalletTransaction.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAllWalletTransaction.fulfilled, (state, action) => {
        state.walletTransaction = action.payload;
        state.loader = false;
      })
      .addCase(getAllWalletTransaction.rejected, (state) => {
        state.loader = false;
      });
  },
});

export default walletSlice.reducer;
