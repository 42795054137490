import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const createCartData = createAsyncThunk(
  "createCartData",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getCartData = createAsyncThunk(
  "getCartData",
  async ({ url, data }) => {
    try {
      const response = await axios.get(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getBuyNowCartData = createAsyncThunk(
  "getBuyNowCartData",
  async ({ url, data }) => {
    try {
      const response = await axios.get(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const updateCart = createAsyncThunk(
  "updateCart",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

// export const deleteCart = createAsyncThunk("deleteCart", async ({ url }) => {
//   try {
//     const response = await axios.delete(url, {
//       headers: {
//         Authorization: localStorage.getItem("isToken"),
//       },
//     });

//     return response?.data;
//   } catch (error) {
//     return error?.response?.data;
//   }
// });
export const getCartId = createAsyncThunk("getCartId", async (data) => {
  try {
    return data;
  } catch (error) {
    return null;
  }
});

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    loaders: false,
    createCart: null,
    getCart: null,
    cartId: null,
    getBuyCart: null,
  },

  reducers: {
    handleRemoveCart: (state, action) => {
      state.getCart = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createCartData.fulfilled, (state, action) => {
        state.loaders = false;
        state.createCart = action?.payload?.data;
      })
      .addCase(getCartData.pending, (state) => {
        state.loaders = true;
      })
      .addCase(getCartData.fulfilled, (state, action) => {
        state.loaders = false;
        state.getCart = action?.payload;
      })
      .addCase(getCartData.rejected, (state) => {
        state.loloadersder = false;
      })
      .addCase(getBuyNowCartData.pending, (state) => {
        state.loaders = true;
      })
      .addCase(getBuyNowCartData.fulfilled, (state, action) => {
        state.loaders = false;
        state.getBuyCart = action?.payload;
      })
      .addCase(getBuyNowCartData.rejected, (state) => {
        state.loaders = false;
      })
      .addCase(getCartId.fulfilled, (state, action) => {
        state.cartId = action?.payload;
      });
  },
});

export const { handleRemoveCart } = cartSlice.actions;
export default cartSlice.reducer;

// run current code
