import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllReviewByProductId = createAsyncThunk(
  "getAllReviewByProductId",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);
export const createReview = createAsyncThunk(
  "createReview",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

const reviewSlice = createSlice({
  name: "review",
  initialState: {
    getAllReview: null,
    loadingReview: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllReviewByProductId.pending, (state) => {
        state.loadingReview = true;
      })
      .addCase(getAllReviewByProductId.fulfilled, (state, action) => {
        state.getAllReview = action.payload?.data;
        state.loadingReview = false;
      })
      .addCase(getAllReviewByProductId.rejected, (state) => {
        state.loadingReview = false;
      });
  },
});

export default reviewSlice.reducer;
