import { Alert, Slide, Snackbar } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "../Redux/alertSlice";
// import { handleAlert } from "../Redux/alertSlice";

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}
const AlertTost = () => {
  const { alerts } = useSelector((state) => state.isAlert);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alerts?.isOpen) {
      setTimeout(() => {
        dispatch(handleAlert({ ...alerts, isOpen: false }));
      }, 3000);
    }
  }, [alerts?.isOpen]);

  return (
    <>
      <Snackbar
        sx={{ display: `${alerts.isOpen ? "block" : "none"}`, zIndex: 10000 }}
        open={alerts?.isOpen}
        onClose={() => dispatch(handleAlert({ ...alerts, isOpen: false }))}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={TransitionLeft}
      >
        <Alert
          variant="filled"
          severity={alerts.type}
          sx={{ width: "100%" }}
          onClose={() => dispatch(handleAlert({ ...alerts, isOpen: false }))}
        >
          {alerts.msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AlertTost;
