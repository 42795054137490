import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const userRegister = createAsyncThunk(
  "userRegister",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const sendOtp = createAsyncThunk("sendOtp", async ({ url, data }) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return response ? response?.data : null;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

export const getUserById = createAsyncThunk("getUserById", async (url) => {
  try {
    const response = await axios.get(url, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return response ? response?.data : null;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

export const loggedUser = createAsyncThunk("loggedUser", async (url) => {
  try {
    const response = await axios.get(url, {
      headers: {
        // "Content-Type": "application/json",
        // authorization: `Bearer ${localStorage.getItem("token")}`,
        authorization: localStorage.getItem("token"),
      },
    });
    return response ? response : null;
  } catch (error) {
    // console.log(error);
    return null;
  }
});

export const userUpdate = createAsyncThunk(
  "userUpdate",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);
export const getDashboard = createAsyncThunk(
  "getDashboard",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);
export const updateUserStatus = createAsyncThunk(
  "updateUserStatus",
  async ({ url }) => {
    try {
      const response = await axios.put(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: {
    userData: null,
    loader: false,
    userOtp: null,
    userProfile: null,
    dashboard: null,
  },
  reducers: {
    handllingLogout: (state) => {
      state.userData = null;
      localStorage.clear();
      // localStorage.removeItem("token");
      // localStorage.removeItem("_id");
      // localStorage.removeItem("fireToken");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userRegister.pending, (state) => {
        state.loader = true;
      })
      .addCase(userRegister.fulfilled, (state, action) => {
        state.userData = action.payload?.data;
        state.loader = false;
      })
      .addCase(userRegister.rejected, (state) => {
        state.userData = null;
        state.loader = false;
      })
      .addCase(sendOtp.pending, (state) => {
        state.loader = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        if (action?.payload?.success === true) {
          localStorage.setItem("otp", action?.payload?.otp);
          state.userOtp = action.payload;
          state.loader = false;
        }
      })
      .addCase(sendOtp.rejected, (state) => {
        state.userData = null;
        state.loader = false;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.userProfile = action.payload?.data;
        state.loader = false;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.dashboard = action.payload;
        state.loader = false;
      });
  },
});

export const { handllingLogout } = userSlice.actions;
export default userSlice.reducer;
