import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@emotion/react";
import { customTheme } from "./Utils";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Store, persistStores } from "./Redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "./Components/Loading";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading/>}>
      <BrowserRouter>
        <ThemeProvider theme={customTheme}>
          <Provider store={Store}>
            <PersistGate loading={null} persistor={persistStores}>
              <App />
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
