import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAgreementData = createAsyncThunk(
  "getAgreementData",
  async ({ url, data }) => {
    try {
      const response = await axios.get(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getAllpartnerTransactionByPartnerUserId = createAsyncThunk(
  "getAllpartnerTransactionByPartnerUserId",
  async ({ url, data }) => {
    try {
      const response = await axios.get(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const createPartnership = createAsyncThunk(
  "createPartnership",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          "Content-Disposition": "form-data",
        },
      });

      return response;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const uploadAgreementPdf = createAsyncThunk(
  "uploadAgreementPdf",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          "Content-Disposition": "form-data",
        },
      });

      return response;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const updatePartnership = createAsyncThunk(
  "updatePartnership",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          "Content-Disposition": "form-data",
        },
      });

      return response;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const becomePartnerSlice = createSlice({
  name: "becomePartnerSlice",
  initialState: {
    loading: false,
    getAgreement: null,
    getAllpartnerTransaction: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAgreementData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAgreementData.fulfilled, (state, action) => {
      state.loading = false;
      state.getAgreement = action?.payload?.data;
    });

    builder.addCase(getAgreementData.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(
      getAllpartnerTransactionByPartnerUserId.fulfilled,
      (state, action) => {
        state.loading = false;
        state.getAllpartnerTransaction = action?.payload?.data;
      }
    );
  },
});

export default becomePartnerSlice.reducer;
