import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const Seo = ({ title, description, image, keyword,icon }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content="My Awesome Page Title" />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="keyword" content={keyword} />
      <meta property="og:image" content={image} />
      <link rel="icon" type="image/png" href={icon} sizes="16x16" />
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.string,
  keyword: PropTypes.string,
};

export default Seo;
