import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#0d8644", // green
    },
    secondary: {
      main: "#db3a3e", // red
    },
    primaryText: {
      main: "#4a4a4a", // dark gray text
    },
    secondaryText: {
      main: "#8E8E8E", // gray text
    },
    cardBackground: {
      main: "#c8f5f4", // indigo
    },
    sectionBackground: {
      main: "#f7fefe", // light indigo
    },
    white: {
      main: "#ffffff", // white
    },
    black: {
      main: "#000000", // black
    },
    outline: {
      main: "#eaeaec", // light gray
    },
    product: {
      main: "#292929", // dark gray it should be used for product name
    },
    warning:{
      main:"#FFFF00",
    }
  },
  typography: {
    fontFamily: [
      'Poppins', 'sans-serif',
    ].join(','),
  },
});
