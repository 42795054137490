import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getDisease = createAsyncThunk("getDisease", async ({ url }) => {
  try {
    const response = await axios.get(url, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
});

export const getDiseaseById = createAsyncThunk(
  "getDiseaseById",
  async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

export const getDiseaseProduct = createAsyncThunk(
  "getDiseaseProduct",
  async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }
);

const diseaseSlice = createSlice({
  name: "disease",
  initialState: {
    Loading: false,
    allDisease: null,
    diseaseProduct: null,
    singleDisease: null,
    diseaseId: null,
  },

  reducers: {
    handleDiseaseId: (state, action) => {
      state.diseaseId = action?.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDisease.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getDisease.fulfilled, (state, action) => {
        state.allDisease = action?.payload?.data;
        state.Loading = false;
      })
      .addCase(getDisease.rejected, (state) => {
        state.Loading = false;
      })
      .addCase(getDiseaseProduct.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getDiseaseProduct.fulfilled, (state, action) => {
        state.diseaseProduct = action?.payload?.data;
        state.Loading = false;
      })
      .addCase(getDiseaseProduct.rejected, (state) => {
        state.Loading = false;
      })
      .addCase(getDiseaseById.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getDiseaseById.fulfilled, (state, action) => {
        state.singleDisease = action?.payload?.data;
        state.Loading = false;
      })
      .addCase(getDiseaseById.rejected, (state) => {
        state.Loading = false;
      });
  },
});

export const { handleDiseaseId } = diseaseSlice.actions;
export default diseaseSlice.reducer;
