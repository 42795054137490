import React from "react";
import style from "./loading.module.css";

const Loading = () => {
  return (
    <div style={{height:"100vh",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
      <div className={style.loader}></div>
    </div>
  );
};

export default Loading;
