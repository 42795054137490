import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllDosh = createAsyncThunk("getAllDosh", async ({ url }) => {
  try {
    const response = await axios.get(url, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {
    console.log(error, "Something Went Wrong !");
    return error?.response?.data;
  }
});
export const getResult = createAsyncThunk("getResult", async (url) => {
  try {
    const response = await axios.get(url, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {
    console.log(error, "Something Went Wrong !");
    return error?.response?.data;
  }
});
export const getResultProduct = createAsyncThunk(
  "getResultProduct",
  async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error, "Something Went Wrong !");
      return error?.response?.data;
    }
  }
);

const assessmentSlice = createSlice({
  name: "assessment",
  initialState: {
    loader: false,
    getDosh: null,
    result: null,
    quesIndex: 0,
    doshProduct: null,
  },
  reducers: {
    handleIncrement: (state, action) => {
      state.quesIndex = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDosh.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAllDosh.fulfilled, (state, action) => {
        state.getDosh = action.payload?.data;
        state.loader = false;
      })
      .addCase(getAllDosh.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getResult.pending, (state) => {
        state.loader = true;
      })
      .addCase(getResult.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loader = false;
      })
      .addCase(getResult.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getResultProduct.pending, (state) => {
        state.loader = true;
      })
      .addCase(getResultProduct.fulfilled, (state, action) => {
        state.doshProduct = action.payload;
        state.loader = false;
      })
      .addCase(getResultProduct.rejected, (state) => {
        state.loader = false;
      });
  },
});
export const { handleIncrement } = assessmentSlice.actions;

export default assessmentSlice.reducer;
