import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getPartnerPage = createAsyncThunk(
  "getPartnerPage",
  async ({ url }) => {
    try {
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllpartnerTransactionByPartnerUserId = createAsyncThunk(
  "getAllpartnerTransactionByPartnerUserId",
  async ({ url }) => {
    try {
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getPartnerShip = createAsyncThunk(
  "getPartnerShip",
  async ({ url }) => {
    try {
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const partnerSlice = createSlice({
  name: "partnerSlice",
  initialState: {
    Loading: false,
    enquiry: null,
    getAllpartnerTransaction: null,
    partnerDetails: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getPartnerPage.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getPartnerPage.fulfilled, (state, action) => {
        state.enquiry = action?.payload?.data;
        state.Loading = false;
      })
      .addCase(getPartnerPage.rejected, (state) => {
        state.Loading = false;
      })
      .addCase(getPartnerShip.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getPartnerShip.fulfilled, (state, action) => {
        state.partnerDetails = action?.payload?.data;   
        state.Loading = false;
      })
      .addCase(getPartnerShip.rejected, (state) => {
        state.Loading = false;
      })
      .addCase(getAllpartnerTransactionByPartnerUserId.pending, (state) => {
        state.Loading = true;
      })
      .addCase(
        getAllpartnerTransactionByPartnerUserId.fulfilled,
        (state, action) => {
          state.getAllpartnerTransaction = action?.payload?.data;
          state.Loading = false;
        }
      )
      .addCase(getAllpartnerTransactionByPartnerUserId.rejected, (state) => {
        state.Loading = false;
      });
  },
});

export default partnerSlice.reducer;
