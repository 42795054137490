import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getNotificationCount = createAsyncThunk(
  "getNotificationCount",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);
export const getNotification = createAsyncThunk(
  "getNotification",
  async ({ url }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

const notificationSlice = createSlice({
  name: "blogs",
  initialState: {
    Loading: false,
    notification: null,
    notiCount: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.notification = action.payload?.data;
        state.loader = false;
      })
      .addCase(getNotification.rejected, (state) => {
        state.Loading = false;
      })
      .addCase(getNotificationCount.fulfilled, (state, action) => {
        state.notiCount = action.payload;
        state.loader = false;
      });
  },
});

export default notificationSlice.reducer;
