import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../Utils/Api";

export const getFilterTabs = createAsyncThunk("getFilterTabs", async (url) => {
  try {
    const res = await http.get(url);
    return res?.data;
  } catch (error) {
    return { data: null };
  }
});
export const getFiltersData = createAsyncThunk(
  "getFiltersData",
  async (url) => {
    try {
      const res = await http.get(url);
      return res?.data;
    } catch (error) {
      return { data: null };
    }
  }
);
export const getMobileFiltersData = createAsyncThunk(
  "getMobileFiltersData",
  async (url) => {
    try {
      const res = await http.get(url);
      return res?.data;
    } catch (error) {
      return { data: null };
    }
  }
);

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    loading: false,
    filterTabs: null,
    filterData: null,
    searchText: null,
    mobileFilterData: null,
  },
  reducers: {
    handleSearch: (state, action) => {
      state.searchText = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilterTabs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFilterTabs.fulfilled, (state, action) => {
        state.loading = false;
        state.filterTabs = action?.payload?.data;
      })
      .addCase(getFilterTabs.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFiltersData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFiltersData.fulfilled, (state, action) => {
        state.loading = false;
        state.filterData = action?.payload;
      })
      .addCase(getFiltersData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getMobileFiltersData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMobileFiltersData.fulfilled, (state, action) => {
        state.loading = false;
        state.mobileFilterData = action?.payload;
      })
      .addCase(getMobileFiltersData.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { handleSearch } = filterSlice.actions;
export default filterSlice.reducer;
