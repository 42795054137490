import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getWishlistByUserId = createAsyncThunk(
  "getWishlistByUserId",
  async ({ url }) => {
    try {
      const data = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return data ? data?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);
export const addWishlist = createAsyncThunk(
  "addWishlist",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);
export const deleteWishlist = createAsyncThunk(
  "deleteWishlist",
  async ({ url, value }) => {
    try {
      const response = await axios.delete(url, {
        data: value,
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return response ? response : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    getWishlist: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWishlistByUserId.pending, (state) => {
        state.loader = true;
      })
      .addCase(getWishlistByUserId.fulfilled, (state, action) => {
        state.getWishlist = action.payload?.data;
        state.loader = false;
      })
      .addCase(getWishlistByUserId.rejected, (state) => {
        state.userData = null;
        state.loader = false;
      });
  },
});

export default wishlistSlice.reducer;
