import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createAddressData = createAsyncThunk(
  "createAddressData",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error, "Something Went Wrong !");
      return error?.response?.data;
    }
  }
);

export const getAddressByUserId = createAsyncThunk(
  "getAddressByUserId",
  async ({ url }) => {
    try {
      const data = await axios.get(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return data ? data?.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

export const deleteAddressData = createAsyncThunk(
  "deleteAddressData",
  async ({ url }) => {
    try {
      const response = await axios.delete(url, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error, "Something Went Wrong !");
      return error?.response?.data;
    }
  }
);

export const selectAddress = createAsyncThunk("selectAddress", async (data) => {
  try {
    return data;
  } catch (error) {
    console.log(error, "Something Went Wrong !");
    return { data: null };
  }
});

export const updateAddress = createAsyncThunk(
  "updateAddress",
  async ({ url, data }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error, "Something Went Wrong !");
      return error?.response?.data;
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState: {
    loader: false,
    getAddress: null,
    selectAdd: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddressByUserId.pending, (state) => {
        state.loader = true;
      })
      .addCase(getAddressByUserId.fulfilled, (state, action) => {
        state.getAddress = action.payload?.data;
        state.loader = false;
      })
      .addCase(getAddressByUserId.rejected, (state) => {
        state.loader = false;
      })
      .addCase(selectAddress.fulfilled, (state, action) => {
        state.loader = false;
        state.selectAdd = action?.payload;
      });
  },
});

export default addressSlice.reducer;
