import React from "react";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { Box } from "@mui/material";

const StickyArrow = () => {
  return (
    <Box
      onClick={() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
      }}
      sx={{
        cursor: "pointer",
        border: "2px solid white",
        borderRadius: "50%",
        width: "2.5rem",
        height: "2.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "primary.main",
        position: "sticky",
        bottom: "15%",
        left: "95%",
      }}
    >
      <KeyboardArrowUpOutlinedIcon color="white" fontSize="large" />
    </Box>
  );
};

export default StickyArrow;
